.table th, .table td {
    padding: 0.45rem !important;
    vertical-align: middle !important;
}

.btn.btn-icon, .btn.drp-icon {
    width: 30px !important;
    height: 30px !important;
    padding: 4px 6px !important;
}

/*Tab*/

.tab-content {
    padding: 25px 25px;
    background: #fff;
    /*box-shadow: 0 3px 10px 0 rgba(0,0,0,.05)*/
}

.tab-content > .tab-pane {
    display: none
}

.tab-content > .active {
    display: block
}

.nav-tabs {
    border-bottom: none;
    background: white
}

.nav-tabs .nav-link {
    border: none;
    color: #888
}

.nav-tabs .nav-link:hover {
    color: #04a9f5
}

.nav-tabs .nav-link.disabled {
    opacity: .7
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: none;
    color: #111;
    /*box-shadow: 0 -5px 10px 0 rgba(0,0,0,.05)*/
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: 10px 24px
}

.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    opacity: .7
}

.nav-tabs {
    border-bottom: none
}

    .nav-tabs .nav-link {
        border-bottom: 1px #d1d9e0 solid;
        color: #888
    }

    .nav-tabs .nav-link:hover {
        color: #04a9f5;
        border-bottom: 3px #04a9f5 solid;
    }

.nav-tabs .nav-link.disabled {
    opacity: .7
}

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-bottom: 3px #04a9f5 solid;
color: #111;
        /*box-shadow: 0 -5px 10px 0 rgba(0,0,0,.05)*/
    }

.nav-pills {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.05)
}

.nav-pills .nav-link {
    border-radius: 5px
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background: #04a9f5;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.2)
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.menu-bot {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    vertical-align: top;
    width: auto;
    margin-bottom: 0px;
    z-index: 7;
    overflow-y: auto;
    display: inline;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
    background: white;
    
}

.menu-bottop {
    background: #eff2f7;
    padding-bottom: 10px;
    padding-top: 10px
}
    .moveNode {
    cursor: grab;
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-bottom: 15px;
    float: left;
    width: 50%;
}

.moveNode span{
    line-height: 50px;
    margin-left: 5px
}

.circle {
    background: #00a9ff;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 23px
}

.circle > div {
    text-align: center;
}

.square {
    background: #ffc261;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 23px
}

.square > div {
        text-align: center;
    }

.squarerou {
    background: #303f9f;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 23px
}

.squarerou > div {
        text-align: center;
    }

.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}


	
	.modal.right .modal-dialog {
		right: 0;
	}

.react-flow__handle {
    width: 9px !important;
    height: 9px !important;
    background: rgb(135, 150, 175) !important
}

.react-flow__handle-top {
    top: -18px !important;
    transform: translate(-50%);
}

.react-flow__handle-bottom {
    bottom: -18px !important;
}

.flowrender {
    min-height: 500px
}


.react-flow {
min-height: 500px
}